html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Courier', sans-serif;
  background: #fff;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  width: 100%;
}

* {
  box-sizing: border-box;
  transition: all 0s;
}

#root {
  position: relative;
  font-family: 'Courier', sans-serif;
  background: #fff;
  padding-left: 288px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

#root,
#root > [data-reactroot] {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

a,
button {
  transition: all 0.2s;
  background: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  font-family: 'Courier', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  outline-color: #66cc99;
  padding: 0;
  text-decoration: none;
  text-decoration-color: #66cc99;
}

a:active {
  color: #777;
}

button.inactive {
  color: lightgrey;
}

img {
  border-radius: 1rem;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  object-fit: cover;
}

input,
textarea {
  background-color: #f0f0f0;
  border: 0;
  border-radius: 28px;
  font-family: 'Courier', sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  outline-color: #66cc99;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
  resize: none;
}

p {
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

div {
  font-weight: 600;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@keyframes slideUpAppear {
  0% {
    transform: translateY(1.25rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.side-menu {
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 288px;
  padding: 0 1rem 2rem;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
  overflow: scroll;
  z-index: 3;
  flex-shrink: 0;
}

.side-menu-header {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: white;
  display: flex;
  align-items: center;
  padding: 18px 0;
  position: sticky;
  top: 0;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
}

.side-menu-sub-title {
  color: #777;
}

.side-menu-photo {
  border-radius: 50%;
  height: 56px;
  width: 56px;
  min-width: 56px;
  min-height: 56px;
  object-fit: cover;
  margin-right: 1rem;
}

.side-menu-button {
  display: block;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
  border-radius: 30px;
  font-size: 1.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.side-menu-button.selected {
  background-color: #333;
  color: white;
}

.side-menu-button.selected:hover {
  background-color: #333;
  color: white;
}

.side-menu-button.selected:active {
  background-color: #ccc;
  color: #333;
}

.side-menu-button:hover {
  /* box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2); */
  background-color: #eee;
}

.side-menu-button:active {
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset; */
  background-color: #ccc;
  color: #333;
}

.menu-button {
  background-color: white;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  font-size: 1.5rem;
  padding: 1rem;
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  margin-right: 1rem;
  display: none;
}

.menu-button:hover {
  /* box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2); */
  background-color: #eee;
}

.menu-button:active {
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset; */
  background-color: #ccc;
}

.side-menu-links {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.icon-link {
  align-items: center;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
  border-radius: 50%;
  display: flex;
  font-size: 1.625rem;
  justify-content: center;
  padding: 1rem;
  width: 58px;
  height: 58px;
  min-width: 58px;
  min-height: 58px;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.icon-link:hover {
  /* box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2); */
  background-color: #eee;
}

.icon-link:active {
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset; */
  background-color: #ccc;
  color: #333;
}

.side-menu-form {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.top-button {
  background-color: white;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  font-size: 1.5rem;
  padding: 1rem;
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
}

.top-button:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.top-button:active {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset;
}

.nav-header {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: white;
  padding: 1rem 3rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
}

.headroom {
  /* background-color: rgba(255, 255, 255, 0.9); */
  background-color: white;
  padding: 1rem 3rem 1rem;
  z-index: 1;
  flex-shrink: 0;
  padding-left: 336px;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
}

.headroom--unfixed {
  padding-left: 3rem;
  box-shadow: none;
  transition: box-shadow 0.3s;
}

.nav-list {
  color: #777;
  font-size: 1.5rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 55vw;
  margin: 0 auto;
}

.nav-list li {
  padding: 1rem 1rem 1rem 0;
}

.nav-list h1,
.nav-list h3,
.nav-list li {
  display: inline-block;
  vertical-align: super;
}

.nav-list h1,
.nav-list h3 {
  vertical-align: text-bottom;
}

.nav-list a {
  color: #333;
  font-size: 1.5rem;
}

.nav-list a:hover {
  text-decoration: underline #66cc99 solid;
}

#content {
  padding: 2rem 3rem 6rem;
  display: block;
  flex: 1 0 auto;
  color: #333;
}

.main-content {
  width: 100%;
  max-width: 55vw;
  margin: 0 auto;
  animation: slideUpAppear 1.5s ease-out;
}

.row,
.mobile-column {
  display: flex;
  flex-direction: row !important;
  align-items: stretch;
}

.row-center {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column !important;
  flex-grow: 1;
}

.links {
  flex-wrap: wrap;
}

.search-row {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 2rem;
  position: relative;
}

.clear {
  position: absolute;
  right: 0;
  top: 0px;
  padding: 1rem;
  font-size: 24px;
  height: 55px;
}

.filter-icon {
  align-self: center;
  align-items: center;
  border-radius: 50%;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  padding: 1rem;
}

.filter-icon:hover {
  background-color: #eee;
}

.filter-icon:active {
  background-color: #ccc;
}

.label {
  margin: 0 0 1rem;
}

.form-label {
  margin: 2rem 0 1rem;
}

.send-button {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  font-size: 26px;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.send-button:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.send-button:active {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset;
}

.submit-button {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 28px;
  font-size: 1.25rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.submit-button:hover {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.submit-button:active {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset;
}

.loading-button {
  color: lightgrey;
  font-size: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.loading {
  align-self: center;
  color: lightgrey;
  font-size: 1rem;
  margin-left: 1rem;
}

.error {
  padding-bottom: 1rem;
  color: red;
}

.contact-error {
  padding: 1rem 0;
  color: red;
}

.title {
  margin-bottom: 2rem;
}

.author {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.time {
  color: #777;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.date {
  color: lightgrey;
  margin-bottom: 2rem;
}

.results {
  color: #777;
  margin-bottom: 2rem;
}

.empty-message {
  color: lightgrey;
}

.rating {
  /* color: #66cc99; */
  color: #777;
  margin-bottom: 2rem;
}

.youtube-video {
  padding-top: 3rem;
}

.note-link {
  font-size: 1.25rem;
  text-decoration: underline #66cc99 solid;
}

.margin-right {
  margin-right: 0.75rem;
}

.note-detail {
  color: #777;
  display: inline;
}

.list-item {
  margin-bottom: 2rem;
  align-items: flex-start;
}

.list-item .photo {
  margin-right: 1rem;
}

.write-photo {
  margin-bottom: 1rem;
  width: 300px;
  object-fit: contain;
}

.list-item .title,
.list-item .author,
.list-item .date,
.list-item .rating {
  margin-bottom: 0.5rem;
  margin-right: 0.75rem;
}

.list-item:hover .title {
  text-decoration: underline #66cc99 solid;
}

.list-item .icon-link {
  font-size: 1.5rem;
  margin: 0 1rem 1rem 0;
  padding: 0.75rem;
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
}

.list-item iframe {
  margin-bottom: 1rem;
}

.item .photo {
  margin-right: 1rem;
}

.item .tags {
  margin-bottom: 0;
  overflow: visible;
  padding: 0;
}

.item .title,
.item .author,
.item .time,
.item .date,
.item .rating {
  margin-bottom: 1rem;
}

.item .icon-link {
  margin: 0 1rem 1rem 0;
  font-size: 1.5rem;
  padding: 0.75rem;
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
}

.screenshots {
  flex-wrap: wrap;
  margin-top: 2rem;
}

.screenshots img {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.note {
  line-height: 1.5;
  margin: 2rem 0;
}

.note-photo {
  padding: 0.5rem 1rem;
  width: 350px;
  object-fit: contain;
}

::selection {
  color: #333;
  background: #66cc99;
}

.apple {
  color: rgb(142, 142, 147);
}
.apple:hover {
  color: rgb(142, 142, 147);
}

.app-store {
  color: #147efb;
}
.app-store:hover {
  color: #147efb;
}

.play-store {
  color: #3bccff;
}
.play-store:hover {
  color: #3bccff;
}

.twitter {
  color: #55acee;
}
.twitter:hover {
  color: #55acee;
}

.substack {
  color: #FF6719;
}
.substack:hover {
  color: #FF6719;
}

.facebook {
  color: #3b5998;
}
.facebook:hover {
  color: #3b5998;
}

.instagram {
  color: #e4405f;
}
.instagram:hover {
  color: #e4405f;
}

.youtube {
  color: #cd201f;
}
.youtube:hover {
  color: #cd201f;
}

.podcast {
  color: #7f3ee6;
}
.podcast:hover {
  color: #7f3ee6;
}

.product-hunt {
  color: #da552f;
}
.product-hunt:hover {
  color: #da552f;
}

.linkedin {
  color: #0077b5;
}
.linkedin:hover {
  color: #0077b5;
}

.spotify {
  color: #1db954;
}
.spotify:hover {
  color: #1db954;
}

.amazon {
  color: #ff9900;
}
.amazon:hover {
  color: #ff9900;
}

.patreon {
  color: #f96854;
}
.patreon:hover {
  color: #f96854;
}

.coffee {
  color: #ff813f;
}
.coffee:hover {
  color: #ff813f;
}

.home-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-links {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.home-button {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 36px;
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
  overflow: hidden;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 8px 56px 8px 8px;
  /* width: calc(50% - 1rem); */
}

.home-button:hover {
  /* box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2); */
  background-color: #eee;
}

.home-button:active {
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset; */
  background-color: #ccc;
  color: #333;
}

.home-button:nth-child(even) {
  margin-right: 0;
}

.home-button img {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 50%;
  margin-right: 1rem;
}

.home-icon {
  /* box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.button-title {
  margin-bottom: 0 !important;
}

.overlay {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15;
}

.modal-content {
  background: #fff;
  border: none;
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  cursor: default;
  display: flex;
  flex-direction: column;
  left: 50%;
  margin-bottom: 1rem;
  max-width: 55vw;
  max-height: 55vh;
  padding: 0rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  z-index: 20;
}

.modal-header {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
}

.close-modal {
  align-self: flex-start;
  align-items: center;
  border-radius: 50%;
  /* box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  justify-content: center;
  padding: 1rem;
}

.modal-title {
  flex-shrink: 0;
  font-size: 1.25rem;
  padding: 0 1rem 1rem;
}

.sort-row {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0 1rem;
}

.tags {
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
  overflow-y: scroll;
}

.tag {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  color: black;
  margin: 0 1rem 1rem 0;
  padding: 0.75rem;
}

.tag-selected {
  background-color: #333;
  color: white;
}

.tags-button {
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.tag-filter {
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
}

.tag-filter svg {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 900px) {
  .mobile-column {
    flex-direction: column !important;
  }

  .mobile-column .photo {
    margin-bottom: 1rem;
  }

  .column {
    width: 100%;
  }

  .side-menu {
    display: none;
  }

  .side-menu.open {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  }

  .side-menu-header {
    padding: 1rem 0;
  }

  #root {
    padding-left: 0;
  }

  .menu-button {
    display: inline;
  }

  .nav-header {
    padding: 1rem 1rem 0;
  }

  .headroom {
    padding: 1rem;
    padding-left: 1rem;
  }

  .nav-list {
    align-items: center;
    max-width: 100%;
  }

  #content {
    padding: 0 1rem 6rem;
  }

  .main-content {
    max-width: 100%;
  }

  .home-content {
    flex-direction: column;
  }

  .home-button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .modal-content {
    border-radius: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .modal-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .icon-link {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  .side-menu-button,
  .top-button,
  .nav-list,
  .nav-list a,
  .home-icon,
  .close-modal {
    font-size: 1.25rem;
  }

  input,
  p,
  textarea,
  .author,
  .time,
  .note-link {
    font-size: 1rem;
  }

  .margin-right {
    margin-right: 0.6rem;
  }

  .title {
    margin-bottom: 1.5rem;
  }

  .note {
    margin: 1.5rem 0;
  }

  .list-item {
    margin-bottom: 1.5rem;
  }

  .clear {
    padding: 13px;
    height: 50px;
  }
}

@media only screen and (max-width: 400px) {
  .__react_component_tooltip {
    z-index: 10 !important;
  }
}
